import { EntityData, updateEntityRecord } from '@app/api/master/entity.api';
import { Card } from '@app/components/common/Card/Card';
import { Table } from '@app/components/common/Table/Table';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { Option, Select } from '@app/components/common/selects/Select/Select';
import { Loading } from '@app/components/loading';
import { VendorPayment, VendorPaymentItem, VendorPaymentType } from '@app/domain';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { selectPaymentRecord } from '@app/store/slices/vendorPaymentSlice';
import { Table as AntTable, Col, FormInstance, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { FC, Suspense, useMemo, useState } from 'react';

type Props = {
  form: FormInstance;
  onFinish: () => void;
  setLoading: (v: boolean) => void;
  loading: boolean;
};

const SpkTab: FC<Props> = ({ form, onFinish, setLoading, loading }) => {
  const dispatch = useAppDispatch();
  const { selected: selectedPayment, records } = useAppSelector((state) => state.vendorPayment);
  const [filteredPayments, setFilteredPayments] = useState<VendorPayment[]>(records);
  const [isFieldsChanged, setFieldsChanged] = useState(false);

  const onCodeSearch = (text: string) => {
    setFilteredPayments(records.filter((v) => v.code.toLowerCase().includes(text.toLowerCase())));
  };

  const onCodeSelect = (value: unknown) => {
    const payment = records.find((v) => v.id === (value as number));
    if (payment) {
      dispatch(selectPaymentRecord(payment));
    }
  };

  const onSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      setFieldsChanged(false);
      onFinish();
    }, 1000);
  };

  const generalColumns: ColumnsType<EntityData> = [
    {
      title: <div style={{ textAlign: 'center' }}>No.</div>,
      render: (_, __, index) => {
        return <div style={{ textAlign: 'center' }}>{index + 1}</div>;
      },
    },
    {
      title: 'Produk',
      render: (_, data) => {
        const ref = data as VendorPaymentItem;
        return ref.product.name;
      },
    },
    {
      title: <div style={{ textAlign: 'center' }}>Qty</div>,
      dataIndex: 'qty',
      render: (_, data) => {
        return <div style={{ textAlign: 'center' }}>{data.qty}</div>;
      },
    },
  ];

  const surveyColumns: ColumnsType<EntityData> = [
    {
      title: 'Markup',
      render: (_, data) => {
        return <div style={{ textAlign: 'right' }}>{`${data.markup_percentage}%`}</div>;
      },
    },
    {
      title: 'Harga Awal',
      render: (_, data) => {
        return <div style={{ textAlign: 'right' }}>{((data.price ?? 0) * (data.qty ?? 0)).toLocaleString('id')}</div>;
      },
    },
    {
      title: 'Harga Markup',
      render: (_, data) => {
        return <div style={{ textAlign: 'right' }}>{(data.subtotal ?? 0).toLocaleString('id')}</div>;
      },
    },
    {
      title: 'Biaya Survey Vendor',
      render: (_, data) => {
        return <div style={{ textAlign: 'right' }}>{(data.vendor_fee ?? 0).toLocaleString('id')}</div>;
      },
    },
  ];

  const updateVendorFee = (e: React.FocusEvent<HTMLInputElement, Element>, data: EntityData) => {
    try {
      updateEntityRecord('tt_vendor_payment_template', data.id, {
        vendor_fee: Number(e.currentTarget.value),
      }).then((res) => {
        console.log(res);
        window.location.reload();
      });
    } catch (e) {
      console.error(e);
    }
  };

  const templateColumns: ColumnsType<EntityData> = [
    {
      title: 'Biaya Vendor',
      render: (_, data) => {
        return (
          <Input
            disabled={false}
            onBlur={(e) => {
              updateVendorFee(e, data);
            }}
            defaultValue={(data.vendor_fee ?? 0).toLocaleString('id')}
            style={{ textAlign: 'right' }}
          />
        );
      },
    },
  ];

  const getColumns = useMemo(() => {
    if (selectedPayment?.installation_type === VendorPaymentType.Survey) {
      return [...generalColumns, ...surveyColumns];
    }
    return [...generalColumns, ...templateColumns];
  }, [selectedPayment?.payments]);

  const renderPayButton = (
    <Row gutter={[10, 10]} wrap={false}>
      <Col xs={24} sm={24} md={{ offset: 8, span: 8 }}>
        <Button
          block
          type="primary"
          htmlType="submit"
          onClick={onSubmit}
          loading={loading}
          disabled={selectedPayment?.state === 'COMPLETE'}
        >
          Bayar
        </Button>
      </Col>
    </Row>
  );

  const getTotal = (data: EntityData[]) => {
    console.log(data);
    return data.reduce((p, v) => p + v.vendor_fee, 0).toLocaleString('id');
  };

  const renderTableSummary = (data: EntityData[]) => {
    console.log(data);

    const isSurvey = selectedPayment?.installation_type === VendorPaymentType.Survey;
    return (
      <>
        <AntTable.Summary.Row style={{ backgroundColor: '#f8f8f8' }}>
          <AntTable.Summary.Cell index={0} />
          <AntTable.Summary.Cell index={1} />
          {isSurvey && <AntTable.Summary.Cell index={2} />}
          {isSurvey && <AntTable.Summary.Cell index={3} />}
          {isSurvey && <AntTable.Summary.Cell index={4} />}
          <AntTable.Summary.Cell index={!isSurvey ? 2 : 5}>
            <div style={{ fontWeight: 'bold', textAlign: 'right', width: '100%' }}>Total:</div>
          </AntTable.Summary.Cell>
          <AntTable.Summary.Cell index={!isSurvey ? 3 : 6}>
            <div style={{ fontWeight: 'bold', textAlign: 'right', width: '100%' }}>
              {!isSurvey ? getTotal(data) : getTotal(data)}
            </div>
          </AntTable.Summary.Cell>
        </AntTable.Summary.Row>
      </>
    );
  };

  const renderTable = (
    <Table
      columns={getColumns}
      dataSource={selectedPayment?.payments ?? []}
      rowKey={(record) => `payment-item-${record.id}`}
      pagination={false}
      loading={loading}
      scroll={{ x: undefined }}
      summary={(data) => {
        return renderTableSummary(data as EntityData[]);
      }}
      bordered
    />
  );
  const renderBody = (
    <BaseButtonsForm
      form={form}
      name="Form Pembayaran Vendor"
      onFieldsChange={() => setFieldsChanged(true)}
      isFieldsChanged={isFieldsChanged}
      setFieldsChanged={setFieldsChanged}
      footer={renderPayButton}
      initialValues={selectedPayment}
      loading={loading}
      disabled
    >
      <Row gutter={{ xs: 10, md: 15 }}>
        <Col xs={24} md={12}>
          <BaseButtonsForm.Item required={true} name="Kode" label="Kode">
            <Select
              defaultValue={selectedPayment?.id ?? undefined}
              onSearch={onCodeSearch}
              onChange={onCodeSelect}
              filterOption={false}
              disabled={loading}
              showSearch
            >
              {filteredPayments.map((dt) => {
                return (
                  <Option value={dt.id} key={`selection-${dt.id}`}>
                    {dt.code}
                  </Option>
                );
              })}
            </Select>
          </BaseButtonsForm.Item>
        </Col>
        <Col xs={24} md={12}>
          <BaseButtonsForm.Item name="vendor.name" label="Nama Vendor">
            <Input
              defaultValue={selectedPayment?.vendor.name ?? ''}
              value={selectedPayment?.vendor.name ?? ''}
              disabled
            />
          </BaseButtonsForm.Item>
        </Col>
        <Col xs={24} md={12}>
          <BaseButtonsForm.Item name="created_date" label="Tanggal SPK">
            <Input defaultValue={selectedPayment?.spk?.created_date ?? ''} disabled />
          </BaseButtonsForm.Item>
        </Col>
        <Col xs={24} md={12}>
          <BaseButtonsForm.Item name="technician.name" label="Teknisi">
            <Input
              defaultValue={selectedPayment?.technician?.name ?? ''}
              value={selectedPayment?.technician?.name}
              disabled
            />
          </BaseButtonsForm.Item>
        </Col>
        <Col xs={24} md={24} style={{ margin: '40px 0' }}>
          {renderTable}
        </Col>
      </Row>
    </BaseButtonsForm>
  );

  return (
    <Card title="Detail Pembayaran Vendor" padding="1.25rem">
      {loading ? <Loading message="Sedang mengambil data" /> : renderBody}
    </Card>
  );
};

export default React.memo(SpkTab);
